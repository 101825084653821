<template>
    <div class="stock-item-type-add">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.stockItemTypeCreate')"
            :title="$t('messages.stockItemTypeCreate')"
            @submitPressed="submitPressed"
        />
        <stock-item-type-form :typeObject="type" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import StockItemTypeForm from './components/StockItemTypeForm.vue'

export default {
    components: {
        TitleBar,
        StockItemTypeForm
    },
    data() {
        return {
            action: null,
            type: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
